<template>
	<div class="body">
		<navTop></navTop>
	
		<div class="wrap wth">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" style="width:500px;" hide-required-asterisk="">
				<el-form-item :label="$t('orderCenter.orderCommentDesc')" prop="desc">
				    <el-input type="textarea" v-model="ruleForm.desc" rows="8"></el-input>
				  </el-form-item>
				<el-form-item :label="$t('orderCenter.qualityScore')" prop="qualityScore">
					<el-rate v-model="value1" style="margin-top: 10px;"></el-rate>
				</el-form-item>
				<el-form-item :label="$t('orderCenter.deliveryScore')" prop="qualityScore">
					<el-rate v-model="value2" style="margin-top: 10px;"></el-rate>
				</el-form-item>
				
				<el-form-item>
				  <el-button type="primary" @click="submitForm('ruleForm')" style="width:100%">{{ $t('operation.confirmSubmit') }}
				  </el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>
<script>
	import {editContent} from "@/api/Invoice";
	import navTop from "@/components/navTop";
	export default{
		components: {navTop},
		data(){
			return{
				value1:null,
				value2:null,
				ruleForm: {
					desc:''
				},
				rules: {
					desc: [
						{required: true, message: this.$t("register.mesCompanyEmpty"), trigger: 'blur'},
					],
				}
			}
		},
		methods:{
			submitForm(formName) {
			  this.$refs[formName].validate((valid) => {
			    if (valid) {
			      this.toSubmit();
			    } else {
			      console.log('error submit!!');
			      return false;
			    }
			  });
			},
			toSubmit(){
				editContent(this.ruleForm).then(res=>{
				  
				  if(res && res.code == 10001){
				    
				    this.$message.warning("修改失败");
				    
					this.$router.push("/applyInvoice");
				  
				  }else{
				    
				    this.$message.success("修改成功");
				    
					this.$router.push("/applyInvoice");
					
				  }
				  
				})
			}
		}
	}
</script>
<style scoped>
	.body{
		width: 100%;
		background-color: #eee;
	}
	.wth{
		/*border: 1px solid red;*/
		width:1200px;
	}
	.wrap{
		background-color: #fff;
		margin: 0px auto;
		padding:40px 0px;
		height: 1080px;
	}
</style>
