import fetch from "@/api/fetch";

const u = "/client/im/invoice/";

export function editContent(data) {
  return fetch({
    url: `${u}/editContent`,
    method: "POST",
    data
  })
}